import React, {useEffect, useState} from 'react'
import {Alert, Button, Card, Col, Form, Modal, Row, Spin} from "antd";
import TextArea from "antd/lib/input/TextArea";
import api from "../../api";
import LoomVideo from "../../Components/LoomVideo";
import './style.css'

const Coaching = () => {

    const [form] = Form.useForm()
    const [questions, setQuestions] = useState([])

    useEffect(() => {
        api.getQuestions().then(setQuestions)
    }, []);

    function sendQuestion(values) {
        api.addQuestion(values.question_text).then(() => {
            form.resetFields()
            Modal.info({
                title: 'Vraag verstuurd',
                content: 'Top! we doen ons best om je vraag binnen 1 dag te beantwoorden!'
            })
            api.getQuestions().then(setQuestions)
        })
    }

    return (
        <div>
            <h1>Stel je moestuin vraag!</h1>
            <p>
                Heb jij een vraag over je moestuin? 🌱
                <br/>
                <br/>Of je nu wilt weten waarom je tomaten niet rood worden of hoe je slakken kunt bestrijden – wij
                staan voor je klaar!
                <br/>Stel je vraag en krijg advies waarmee je jouw oogst nog beter maakt. 🥕🍅
                <br/>
                <br/>Laat je vraag hieronder achter en laten we samen je groene vingers nog groener maken! 💬🪴
            </p>
            <Form form={form} onFinish={sendQuestion} layout={"vertical"}>
                <Form.Item name={"question_text"} label={"Je vraag"} rules={[
                    {
                        required: true,
                        message: 'Vul je vraag in'
                    }
                ]}>
                    <TextArea rows={4} placeholder="Stel je vraag"/>
                </Form.Item>
                <Form.Item>
                    <Button htmlType={"submit"} type="primary">Verstuur</Button>
                </Form.Item>
            </Form>

            <h1>Jouw vragen (en antwoorden)</h1>

            {questions.map((question) => (
                <>
                    <Row justify={"end"}>
                        <Col md={16} xs={24} className={"admin-answer"}>
                            {question.answer && (
                                <>
                                    <div className={"message"}>
                                        {question.answer.split("\n").map((item, key) => (
                                            <span key={key}>{item}<br/></span>
                                        ))}
                                        {question.loom_video && (
                                            <LoomVideo src={question.loom_video}/>
                                        )}
                                    </div>
                                </>
                            )}


                            {!question.answer && (
                                <div className={"message"}>
                                    We gaan je vraag z.s.m. behandelen. Onthoud wel, betalende leden hebben voorrang
                                        <br/>Je ontvangt een e-mail als we je vraag hebben beantwoord!
                                </div>
                            )}
                        </Col>
                    </Row>
                    <Row className={"mar-b-1"}>
                        <Col md={16} xs={24} className={"user-question"}>
                            <h2>Jouw vraag:</h2>
                            <div className={"message"}>
                                {question.question.split("\n").map((item, key) => (
                                    <span key={key}>{item}<br/></span>
                                ))}
                            </div>
                        </Col>
                    </Row>

                </>
            ))}
        </div>
    )
}

export default Coaching