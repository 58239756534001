import axios from 'axios';
import {message} from "antd";
import {GENERIC_ERROR_MESSAGE} from "../constants";

let baseURL = "https://api.oogst.app"

if (window.location.hostname === "garden-frontend.local") {
    baseURL = "http://garden-backend.local"
} else if (window.location.hostname === "localhost") {
    baseURL = "http://localhost:9000"
}

const api = axios.create({
    baseURL: baseURL,
    timeout: 250000,
    headers: {
        // eslint-disable-next-line no-undef
        'Content-Type': 'application/json',
    },
});

api.interceptors.request.use((config) => {
    // // Fetch token from store
    const token = localStorage.getItem('token');

    if (token) {
        // Set token in Authorization header if token exists
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

api.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        // check if we get a 401 error
        if (error.response.status === 401) {
            // if we get a 401 error, remove the token from storage
            localStorage.removeItem('token');
            // redirect to login
            window.location.href = '/login?token_error=1';
            return
        }

        message.info(GENERIC_ERROR_MESSAGE)

        return Promise.reject(error);
    }
);

const gardenApi = {
    register(email, name, password, company) {
        return api.post('/register', {email, password, name, company})
    },
    requestPasswordReset(email) {
        return api.post('/request-password-reset', {email})
    },
    resetPassword(user_id, token, password) {
        return api.post(`/reset-password`, {user_id, token, password})
    },
    login(email, password) {
        return api.post('/login', {email, password})
    },
    gardens() {
        return api.get('/gardens');
    },
    deleteGarden(id) {
        return api.delete(`/gardens/${id}`)
    },
    updateGarden(id, name) {
        return api.put(`/gardens/${id}`, {name})
    },
    getGardenTodos(garden_id) {
        return api.get(`/gardens/${garden_id}/todos`)
    },
    createGarden(name) {
        return api.post('/gardens', {name})
    },
    garden(id) {
        return api.get(`/gardens/${id}`)
    },
    beds(id) {
        return api.get(`/gardens/${id}/beds`)
    },
    bed(garden_id, bed_id) {
        return api.get(`/gardens/${garden_id}/beds/${bed_id}`)
    },
    getBedCropAdvice(garden_id, bed_id) {
        return api.get(`/gardens/${garden_id}/beds/${bed_id}/crop-advice`)
    },
    planting(garden_id, bed_id, planting_id) {
        return api.get(`/gardens/${garden_id}/beds/${bed_id}/plantings/${planting_id}`)
    },
    addBed(garden_id, bed) {
        return api.post(`/gardens/${garden_id}/beds`, bed)
    },
    updateBed(garden_id, bed_id, bed) {
        return api.put(`/gardens/${garden_id}/beds/${bed_id}`, bed)
    },
    removeBed(garden_id, bed_id) {
        return api.delete(`/gardens/${garden_id}/beds/${bed_id}`)
    },
    crops({
              search = null,
              sort = "id_desc",
              plant_in = [],
              sow_in = [],
              harvest_in = []
          }) {
        let url = '/crops'
        let queries = []
        if (search) {
            queries.push(`search=${search}`)
        }
        if (sort) {
            queries.push(`sort=${sort}`)
        }
        if (plant_in.length > 0) {
            queries.push(`plant_in=${plant_in.join(',')}`)
        }
        if (sow_in.length > 0) {
            queries.push(`sow_in=${sow_in.join(',')}`)
        }
        if (harvest_in.length > 0) {
            queries.push(`harvest_in=${harvest_in.join(',')}`)
        }

        if (queries.length > 0) {
            url += '?' + queries.join('&')
        }

        return api.get(url)
    },
    addPlanting(planting) {

        if (planting.harvest_at) {
            planting.harvest_at = planting.harvest_at.format('YYYY-MM-DD')
        }

        if (planting.transplant_at) {
            planting.transplant_at = planting.transplant_at.format('YYYY-MM-DD')
        }

        if (planting.sow_at) {
            planting.sow_at = planting.sow_at.format('YYYY-MM-DD')
        }

        return api.post('/plantings', planting)
    },
    removePlanting(id) {
        return api.delete('/plantings/' + id)
    },
    companyCrops(company_id) {
        return api.get('/company/' + company_id + '/crops')
    },
    updatePlanting(id, planting) {
        return api.put('/plantings/' + id, planting)
    },
    search(query) {
        return api.post('/search', {query})
    },
    getCropInfo(crop) {
        return api.post('/crops/get-info', crop)
    },
    deleteCrop(id) {
        return api.delete('/crops/' + id)
    },
    updateCrop(id, crop) {
        return api.put('/crops/' + id, crop)
    },
    createCrop(crop) {
        return api.post('/crops', crop)
    },
    addReservation(name, email, phone, crop_ids, company_id) {
        return api.post('/reservations', {name, email, phone, crop_ids, company_id})
    },
    reservations() {
        return api.get('/reservations')
    },
    getCropsWithReservations() {
        return api.get('/crops/reservations')
    },
    getCropReservationCustomers(crop_id) {
        return api.get(`/crops/${crop_id}/customers`)
    },
    uploadToCdn(file) {
        const formData = new FormData();
        formData.append("file", file);
        return api.post("/cdn/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
    },
    logUpdate(update) {
        return api.post(`/updates`, update)
    },
    getPlantingUpdates(id) {
        return api.get(`/plantings/${id}/updates`)
    },
    deleteUpdate(update_id) {
        return api.delete(`/updates/${update_id}`)
    },
    getAllUpdates(id) {
        return api.get(`/updates`)
    },
    sendUpdates(update_ids, description, subject) {
        return api.post(`/updates/send`, {update_ids, description, subject})
    },
    isLoggedIn() {
        return localStorage.getItem('token')
    },

    // ADMIN ROUTES
    isAdmin() {
        const jwt = localStorage.getItem('token');
        // Parse the JWT token and check if it has the is_admin flag set
        if (jwt) {
            try {
                // Decode the payload
                const base64Url = jwt.split('.')[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(
                    atob(base64)
                        .split('')
                        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                        .join('')
                );
                const payload = JSON.parse(jsonPayload);
                return payload.is_admin;
            } catch (e) {
                return false;
            }
        }
        return false;
    },
    impersonate(user_id) {
        return api.get(`/admin/impersonate/${user_id}`)
    },
    getAdminStats(user_id) {
        return api.get(`/admin/stats`)
    },
    get_all_users(user_id) {
        return api.get(`/admin/users`)
    },
    get_all_blog_posts() {
        return api.get(`/admin/blog-posts`)
    },
    get_blog_url(slug) {
        if (baseURL === "https://api.oogst.app") {
            return `https://www.oogst.app/blog/${slug}`
        }
        return `http://localhost:9002/blog/${slug}`
    },
    add_blog_post(values) {
        return api.post(`/admin/blog-posts`, values)
    },
    generate_blog_post_ideas(prompt) {
        return api.post(`/admin/blog-posts/generate-ideas`, {prompt})
    },
    addTeamMember(name, email) {
        return api.post(`/team`, {name, email})
    },
    updateTeamMember(id, values) {
        return api.put(`/team/${id}`, values)
    },
    getTeamMembers() {
        return api.get(`/team`)
    },
    deactivateTeamMember(id) {
        return api.delete(`/team/${id}`)
    },
    getInvite(id, token) {
        return api.get(`/team/invite/${id}/${token}`)
    },
    acceptInvite(id, token, password) {
        return api.post(`/team/accept/${id}/${token}`, {password})
    },
    get_plan() {
        return api.get(`/my/plan`)
    },
    getMyCompany() {
        return api.get(`/my/company`)
    },
    updateCompany(company) {
        return api.put(`/my/company`, company)
    },
    retryPayment() {
        return api.post(`/payment/retry`)
    },
    upgradePlan(plan_id, discount_code) {
        return api.post(`/payment/upgrade`, {plan_id, discount_code})
    },
    getAllPlans() {
        return api.get(`/plans`)
    },
    getDiscountCode(code) {
        return api.get(`/discount/${code}`)
    },
    getMe() {
        return api.get(`/me`)
    },
    getCompanyBySlug(slug) {
        return api.get(`/company/slug/${slug}`)
    },
    subscribeToCompany(company_id, name, email) {
        return api.post(`/company/${company_id}/subscribe`, {name, email})
    },
    getProductSearchFilters() {
        return api.get(`/products/filters`)
    },
    getAllProducts() {
        return api.get(`/products`)
    },
    addQuestion(question_text) {
        return api.post(`/questions`, {question_text})
    },
    getQuestions() {
        return api.get(`/questions`)
    },
    answerQuestion(id, answer_text, loom_video) {
        return api.post(`/admin/questions/${id}`, {answer_text, loom_video})
    },
    getOpenQuestions() {
        return api.get(`/admin/questions/open`)
    },
    getQuestionThread(user_id) {
        return api.get(`/admin/questions/thread/${user_id}`)
    }
}

export default gardenApi;
