import {Button} from "antd";
import dayjs from "dayjs";
import api from "../../api";
import {ArrowRightOutlined} from "@ant-design/icons";
import React from "react";
import './style.css'

export default function PlanBox({isActive, plan, company}) {
    return (
        <div className={(isActive) ? "plan-box active-plan" : "plan-box"}>
            <h2>{plan.name}</h2>
            {plan.price > 0 && (
                <>
                    <h3>€ {plan.price} voor 1 jaar</h3>
                </>
            )}
            {plan.price === 0 && (
                <h3>Voor altijd gratis!</h3>
            )}
            {plan.price === -1 && (
                <h3>Vraag offerte aan</h3>
            )}
            <p>Inclusief 21% BTW</p>

            <ul className={"plan-features"}>
                <li>Onbeperkt aantal tuinen</li>
                <li> Onbeperkt aantal bedden per tuin</li>

                {(plan.crop_limit && plan.crop_limit > 0) ? (
                    <li>
                        <b>Maximaal {plan.crop_limit}</b> gewassen
                    </li>
                ) : (
                    <li>
                        <b>Onbeperkt aantal</b> gewassen
                    </li>
                )}
                <li>Rij en plantafstand calculator</li>
                <li>Zaai, verplant en oogsttijden calculator</li>
                {(plan.has_plukkers) && (
                    <li>Koppeling met Plukkers.com</li>
                )}
                {(plan.has_bolster) && (
                    <li>Koppeling met Bolster.nl</li>
                )}

                {(plan.has_team) && (
                    <li>Manage je teamleden en hun taken</li>
                )}
            </ul>

            {(isActive && company) && (
                <>
                    <Button size={"large"} disabled>Je huidige pakket</Button>
                    {(company.plan_expires_at && (
                        <p className={"mar-t-1"}>
                            Je account verloopt op:<br/>
                            {dayjs(company.plan_expires_at).format("dddd D MMMM YYYY")}
                        </p>
                    ))}
                    {(plan.price > 0 && (
                            <Button type={"primary"} onClick={() => {
                                api.upgradePlan(plan.id).then(({url}) => {
                                    document.location = url
                                })
                            }}>Met 1 jaar verlengen</Button>)
                    )}
                </>
            )}
            {(!isActive && plan.requires_contact) && (
                <Button size={"large"} type={"dashed"} target={"_blank"} href={"https://koalendar.com/e/kletsen-met-stef/"}>Plan een gesprek</Button>
            )}
            {(!isActive && !plan.requires_contact && plan.price > 0) && (
                <>
                    <Button type={"primary"} size={"large"} href={`/pay/${plan.id}`}>
                        Selecteer pakket <ArrowRightOutlined/>
                    </Button>

                    <p className={"text-center mar-t-1"}>Niet goed? Geld terug!</p>
                    <p className={"text-center mar-t-1"}>Dit
                        is <strong>geen</strong> abonnement!<br/><strong>Geen</strong> automatische verlenging!</p>
                </>
            )}

        </div>
    )
}