import React, {useEffect, useState} from 'react'
import './style.css'
import {LOOM_VIDEOS} from "../../constants";
import {useLocation} from "react-router-dom";
import {getVideoForPage} from "../../helpers";



export default function LoomVideo({src}) {
    const [videoSource, setVideoSource] = useState();
    const location = useLocation();
    useEffect(() => {
        if(src) {
            setVideoSource(src.replace("/share/", "/embed/"))
            return
        }

        const video = getVideoForPage(location);

        if(video) {
            setVideoSource(video)
        }

    }, []);

    if(!videoSource) {
        return null
    }

    return (
        <div className={"loom-wrap"}>
            <iframe src={videoSource} frameBorder="0" allowFullScreen={true} webkitallowfullscreen="true"
                    mozallowfullscreen="true"/>
        </div>
    )

}