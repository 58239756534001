import React, {useEffect, useState} from 'react'
import {Button, Col, Row, Tabs} from "antd";

export default function CropSearchResults({searchResults, onSelect, label, showOwnCrops}) {

    const [searchTabItems, setSearchTabItems] = useState([]);

    useEffect(() => {
        let searchTabItems = []
        if(searchResults.user && showOwnCrops) {
            searchTabItems.push({label: "Eigen Gewassen", key: "1", children: makeSearchResultRow(searchResults.user)})
        }
        if (searchResults.plukkers) {
            searchTabItems.push({label: "Plukkers.com", key: "2", children: makeSearchResultRow(searchResults.plukkers)})
        }
        if(searchResults.bolster) {
            searchTabItems.push({label: "De Bolster", key: "3", children: makeSearchResultRow(searchResults.bolster)})
        }
        setSearchTabItems(searchTabItems)
    }, [searchResults]);

    function makeSearchResultRow(crops) {
        return crops.map((crop, i) => {
            return (<Row key={crop.name + '-' + i} style={{marginBottom: '18px'}}>
                <Col span={4}>
                    <img src={crop.image} alt={crop.name} style={{maxWidth: '48px', maxHeight: '48px'}}/>
                </Col>
                <Col span={15}>
                    <h4>{crop.name} - {crop.variety}</h4>
                    {crop.supplier ? <small>{crop.supplier}</small> : null}
                </Col>
                <Col label={crop.name} name="crop_id" span={5} className={"text-right"}>
                    <Button onClick={() => onSelect(crop)}>
                        {label}
                    </Button>
                </Col>
            </Row>)
        })
    }

    return <Tabs defaultActiveKey="1" items={searchTabItems}></Tabs>

}