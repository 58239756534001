import {useLocation} from "react-router-dom";
import {Button, Modal} from "antd";
import {PlaySquareOutlined} from "@ant-design/icons";
import "./style.css"
import {useState} from "react";
import LoomVideo from "../LoomVideo";
import {getVideoForPage} from "../../helpers";

function HeaderHelpLink() {

    const [modalOpen, setModalOpen] = useState(false);
    const location = useLocation();
    function showVideo(){
        setModalOpen(true)
    }


    const video = getVideoForPage(location);
    if(!video) {
        return null
    }

    return (
        <>
            <Modal open={modalOpen} width={"90%"} destroyOnClose={true} onCancel={() => setModalOpen(false)} footer={(
                <Button onClick={() => setModalOpen(false)}>Sluiten</Button>
            )}>
                {modalOpen && (
                    <LoomVideo/>
                )}
            </Modal>
            <div className={"header-help-link-wrap"}>
                <Button onClick={() => showVideo()}>
                    <PlaySquareOutlined/> Hoe werkt dit?
                </Button>
            </div>
        </>
    )
}

export default HeaderHelpLink