import React, {useEffect, useState} from 'react'
import {Button, Col, Form, Input, Row, Spin, Table} from "antd";
import api from "../../api";
import {useParams} from "react-router-dom";
import PlanBox from "../../Components/PlanBox";
import {printPrice} from "../../helpers";

export default function PayForPlan() {

    const {planId} = useParams()
    const [form] = Form.useForm()
    const [plans, setPlans] = useState([])
    const [discountCode, setDiscountCode] = useState(null)

    let selectedPlan = null

    useEffect(() => {
        api.getAllPlans().then(setPlans)
    }, []);


    if (plans.length > 0) {
        selectedPlan = plans.find(p => p.id === parseInt(planId))
    }

    function makePayment() {
        api.upgradePlan(planId, discountCode ? discountCode.code : null).then(({url}) => {
            document.location = url
        })
    }

    function loadDiscountCode() {
        console.log(form.getFieldValue('discount_code'))
        api.getDiscountCode(form.getFieldValue('discount_code')).then(setDiscountCode)
    }

    if (!selectedPlan) {
        return <Spin/>
    }

    return (
        <Row justify={"space-around"}>
            <Col md={8}>
                <h1 className={"text-center"}>Je gekozen pakket</h1>
                <PlanBox plan={selectedPlan} isActive={true}></PlanBox>
            </Col>
            <Col md={8}>
                <Form form={form} layout={"vertical"} onFinish={makePayment}>
                    <h1>Besteloverzicht</h1>

                    <table style={{width: '100%'}} className={"order-overview"}>
                        <thead>
                        <tr>
                            <th className={"text-left"}>Aantal</th>
                            <th className={"text-left"}>Product</th>
                            <th className={"text-right"}>Prijs</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1x</td>
                            <td>{selectedPlan.name}</td>
                            <td className={"text-right"}>{printPrice(selectedPlan.price)}</td>
                        </tr>
                        {discountCode && (
                            <tr>
                                <td>1x</td>
                                <td>Korting "{discountCode.code}"</td>
                                <td className={"text-right"}>-{printPrice(discountCode.discount)}</td>
                            </tr>
                        )}
                        <tr>
                            <td></td>
                            <td><strong>Te betalen</strong></td>
                            <td className={"text-right"}>
                                <strong>
                                    {printPrice(discountCode ? selectedPlan.price - discountCode.discount : selectedPlan.price)}
                                </strong>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                    <Form.Item label={"Kortingscode"} name={"discount_code"}>
                        <Input placeholder={"Kortingscode"} suffix={(
                            <Button onClick={loadDiscountCode}>Toevoegen</Button>
                        )}/>


                    </Form.Item>


                    <Form.Item className={"text-right"}>
                        <Button htmlType={"submit"} size={"large"} type={"primary"}>Naar betalen</Button>
                    </Form.Item>

                    <p>Je kunt betalen met iDeal en Bancontact. Je kunt de betaalmethode kiezen als je op "Naar betalen"
                        klikt.</p>
                    <img src={"/static/img/ideal.svg"}/> <img src={"/static/img/bancontact.svg"}/>

                </Form>
            </Col>
        </Row>
    )
}