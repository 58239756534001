// export function calculateMaxPlantsInBed(length, width, plantRowDistance, plantDistanceInRow) {
//   // Calculate the number of plants based on rows along the length
//   let rowsLength = Math.floor(length / plantRowDistance);
//   let plantsInRowLength = Math.floor(width / plantDistanceInRow);
//   let totalPlantsLength = rowsLength * plantsInRowLength;
//
//   // Calculate the number of plants based on rows along the width
//   let rowsWidth = Math.floor(width / plantRowDistance);
//   let plantsInRowWidth = Math.floor(length / plantDistanceInRow);
//   let totalPlantsWidth = rowsWidth * plantsInRowWidth;
//
//   // Return the maximum number of plants
//   return Math.max(totalPlantsLength, totalPlantsWidth);
// }
//

import dayjs from "dayjs";
import {LOOM_VIDEOS, PLANTING_STATUS} from "./constants";

export function calculateMaxPlantsInBed(length, width, plantRowDistance, plantDistanceInRow) {
    // Number of rows we can have in the bed
    const rows = Math.floor(length / plantRowDistance);

    // Number of plants we can have in a row
    const plantsInRow = Math.floor(width / plantDistanceInRow);

    // Total number of plants
    const totalPlants = rows * plantsInRow;

    return totalPlants;
}


export function getFirstSowableDate(monthNumber) {
    const today = dayjs();
    const currentMonth = today.month() + 1; // month() returns 0-11, so add 1 for 1-12 range
    let targetYear = today.year();

    if (monthNumber < currentMonth) {
        targetYear += 1; // Move to the next year if the month has passed this year
    }

    return dayjs().year(targetYear).month(monthNumber - 1).startOf('month'); // monthNumber - 1 as month() uses 0-11
}

export function calculateCropDates(crop, user_sow_at) {

    const plant_min = crop.plant_min ? crop.plant_min : crop.sow_min;

    const daysToTransplant = (plant_min - crop.sow_min);
    const daysToHarvest = (crop.harvest_min - plant_min);
    const sow_at = user_sow_at ? user_sow_at : getFirstSowableDate(crop.sow_min);

    return {
        sow_at: sow_at,
        transplant_at: sow_at.add(daysToTransplant, 'months'),
        harvest_at: sow_at.add(daysToTransplant + daysToHarvest, 'months')
    }
}

export function calculateRowsAndPlants(bed, crop) {

    const rows = Math.floor(bed.width / crop.row_distance);
    const plants = Math.floor(bed.length / crop.plant_distance);

    return {
        rows: rows == 0 ? 1 : rows,
        plants_per_row: plants == 0 ? 1 : plants
    }
}

export function bedDimensionsToHuman(bed) {

    let width = bed.width
    let widthUnit = "cm"

    let length = bed.length
    let lengthUnit = "cm"

    if (bed.width > 100) {
        width = bed.width / 100
        widthUnit = "m"
    }
    if (bed.length > 100) {
        length = bed.length / 100
        lengthUnit = "m"
    }


    return `${width}${widthUnit}x${length}${lengthUnit}`
}


export function filterPlanting(p, endDate, hideHarvested) {

    if (!hideHarvested) {
        hideHarvested = localStorage.getItem('hideHarvested') === 'true'
    }

    // Filter out everything without a crop
    if (!p.crop) {
        return false
    }

    if (!p.harvest_at && !p.sow_at && !p.transplant_at) {
        return true
    }

    if (dayjs(p.harvest_at).year() !== endDate.year()
        && dayjs(p.harvest_at).year() !== endDate.year()
        && dayjs(p.sow_at).year() !== endDate.year()
    ) {
        return false
    }

    if (hideHarvested && p.status === PLANTING_STATUS.HARVESTED) {
        return false
    }

    return true
}

export function getVideoForPage(location) {
    const video = LOOM_VIDEOS.filter((video) => {
        return location.pathname.startsWith(video.pathname)
    })

    if (video.length > 0) {
        return video[0].loom
    }
    return null
}

export function printPrice(price) {
    return `€ ${price.toFixed(2).replace('.', ',')}`
}