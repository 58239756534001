import React, {useEffect, useState} from 'react'
import {Alert, Button, Col, Form, Input, message, Row} from "antd";
import {PlaySquareOutlined} from "@ant-design/icons";
import {useNavigate} from "react-router-dom";
import LoomVideo from "../../Components/LoomVideo";

export default function Welcome() {

    const [eventSend, setEventSend] = useState(false)
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (eventSend) {
            return
        }
        window.gtag('event', 'lead', {'send_to': 'AW-939836725/ZCERCOuLqv8BELWKk8AD'});
        setEventSend(true)
    });

    return (
        <Row justify={"center"}>
            <Col className={"text-center"} md={18} xs={24}>
                <h1>Welkom!</h1>
                <p>Welkom bij de club van oogsters!</p>

                <h2>Wat wil je gaan oogsten?</h2>
                <Row justify={"center"}>
                    <Col xs={24} md={12}>
                        <Form form={form} layout={"horizontal"} onFinish={(values) => {
                            navigate('/crops?search=' + values.query + "&onboarding=1")
                        }}>
                            <Form.Item label={""} name={"query"}>
                                <Input size={"large"} placeholder={"Tomaat"}/>
                            </Form.Item>
                            <Form.Item>
                                <Button htmlType={"submit"} type={"primary"} size={"large"}>Volgende</Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <h1>Kijk eerst deze video!</h1>
                <LoomVideo/>

            </Col>


        </Row>
    )
}